import { createSlice } from "@reduxjs/toolkit"
import { serviceApi } from "Reducers/api"
import { TResourceRelationship } from "Types/example"


interface initialState {
    listData: typeof TResourceRelationship[],
    total: number,
    limit: number,
    page: number
}
const initialState: initialState = {
    listData: [],
    total: 0,
    limit: 0,
    page: 1,
}

export const resourceRelationshipSlice = createSlice({
    name: "resourceRelationship",
    initialState,
    reducers: {
        list: (state) => {

        },
        save: (state) => {

        },
        delete: (state) => {

        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(serviceApi.endpoints.getResourceRelationshipList.matchFulfilled, (state, action) => {
            state.listData = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.page = action.payload.page;
        })
    }
})

export default resourceRelationshipSlice;
