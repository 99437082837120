import LoginLayout from "Layouts/login";
import MainLayout from "Layouts/main";
import { LoginPage, AuthPage, NotfoundPage, MonitorPage, PlayerPage, CampaignPage, ResourcePage, AccountPage, AppPreviewPage, CompanyPage, RatePage, ResourceRelationshipPage } from "Pages";
import { RootRouter } from "./root";

interface ItemType {
    path: string,
    element: any,
    errorElement?: any,
    exact?: boolean,
}

export interface RouterType {
    path?: string,
    element: any,
    errorElement?: any,
    children: ItemType[],
    exact?: boolean,
}

export const AllRouter: RouterType[] = [
    {
        element: <LoginLayout />,
        exact: true,
        errorElement: <NotfoundPage />,
        children: [
            {
                path: RootRouter.Auth.Login,
                element: <LoginPage />
            }
        ]
    },
    {
        element: <MainLayout />,
        children: [
            // {
            //     path: RootRouter.Home,
            //     element: <HomePage />
            // },
            {
                path: RootRouter.Monitor,
                element: <MonitorPage />,
                exact: true,
            },
            {
                path: RootRouter.PlayerPage,
                element: <PlayerPage />,
                exact: true,
            },
            {
                path: RootRouter.CampaignPage,
                element: <CampaignPage />,
                exact: true,
            },
            {
                path: RootRouter.ResourcePage,
                element: <ResourcePage />,
                exact: true,
            },
            {
                path: RootRouter.AccountPage,
                element: <AccountPage />,
                exact: true,
            },
            {
                path: RootRouter.CompanyPage,
                element: <CompanyPage />,
                exact: true,
            },
            {
                path: RootRouter.AuthPage,
                element: <AuthPage />,
                exact: true,
            },
            {
                path: RootRouter.RatePage,
                element: <RatePage />,
                exact: true,
            },
            {
                path: RootRouter.ResourceRelationshipPage,
                element: <ResourceRelationshipPage />,
                exact: true,
            },
        ]
    }
]

export const AppRouter: RouterType[] = [
    {
        element: null,
        errorElement: <NotfoundPage />,
        children: [
            {
                path: RootRouter.App.Preview,
                element: <AppPreviewPage />
            }
        ]
    }
]