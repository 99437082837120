import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetAuthCookie } from 'Utils'
import { ConfigApp } from 'Utils/contants'
import { stringify } from 'qs';

const emptySplitApi = {
    baseQuery: fetchBaseQuery({
        baseUrl: ConfigApp.baseUrl,
        credentials: "include",
        prepareHeaders: (headers, api) => {
            headers.set(ConfigApp.apiKey, GetAuthCookie())
        },
    })
}


export const serviceApi = createApi({
    reducerPath: "serviceApi",
    ...emptySplitApi,
    endpoints: builders => ({
        postLogin: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.user.login,
                method: "post",
                body: values
            })
        }),
        getLogin: builders.mutation({
            query: () => ({
                url: ConfigApp.api.user.login
            })
        }),
        // monitor
        getMonitorList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.monitor.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        postMonitorSave: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.monitor.save,
                method: "post",
                body: values
            })
        }),
        deleteMonitor: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.monitor.delete,
                method: "delete",
                body: values
            })
        }),
        setMonitorCompany: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.monitor.setCompany,
                method: "post",
                body: values
            })
        }),
        //resource
        getResourceList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.resource.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        postResourceUpload: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.resource.upload,
                method: "post",
                body: values
            })
        }),
        putResourceUpdate: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.resource.update}/${values._id}`,
                method: "put",
                body: values
            })
        }),
        deleteResource: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.resource.delete,
                method: "delete",
                body: values
            })
        }),
        //resource
        getResourceRelationshipList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.resourceRelationship.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        postResourceRelationship: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.resourceRelationship.upsert,
                method: "post",
                body: values
            })
        }),
        deleteResourceRelationship: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.resourceRelationship.delete,
                method: "delete",
                body: values
            })
        }),
        // Player
        getPlayerList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.player.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        getPlayerLog: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.player.log}?${stringify(values)}`,
                method: "get",
            })
        }),
        getPlayerUpdateCampaign: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.player.updateCampaign,
                method: "post",
                body: values
            })
        }),

        // Campaign || program
        getCampaignList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.campaign.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        getCampaignPreviewContent: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.campaign.previewContent}?${stringify(values)}`,
                method: "get"
            }),
        }),
        getCampaignCreate: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.campaign.create,
                method: "post",
                body: values
            })
        }),
        updateCampaign: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.campaign.update,
                method: "put",
                body: values
            })
        }),
        getCampaignSaveTemplate: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.campaign.saveTemplate}/${values.id}`,
                method: "post",
                body: new URLSearchParams({
                    layout: JSON.stringify(values.layout),
                    content: values.content,
                }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
        }),
        DeleteCampaign: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.campaign.delete,
                method: "delete",
                body: values
            })
        }),
        getCampaignSelect: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.campaign.select}?${stringify(values)}`,
                method: "get",
            })
        }),
        // schedule
        getScheduleList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.schedule.list}/${values.id}?${stringify(values.pagination)}`,
                method: "get",
            })
        }),
        postScheduleSave: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.schedule.save,
                method: "post",
                body: values
            })
        }),
        DeleteSchedule: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.schedule.delete,
                method: "delete",
                body: values
            })
        }),
        // group
        getGroupList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.group.list}/${values.type}?${stringify(values.pagination)}`,
                method: "get",
            })
        }),
        updateGroup: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.group.update}/${values.id}`,
                method: "put",
                body: values.body
            })
        }),
        createGroup: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.group.list}/${values.type}`,
                method: "post",
                body: values.body
            })
        }),
        deleteGroup: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.group.delete,
                method: "delete",
                body: values
            })
        }),
        //Company
        getCompanyList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.company.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        createCompany: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.company.create,
                method: "post",
                body: values
            })
        }),
        updateCompany: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.company.update}/${values.id}`,
                method: "put",
                body: values.body
            })
        }),
        deleteCompany: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.company.delete,
                method: "delete",
                body: values
            })
        }),
        getCompanyAll: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.company.all}?${stringify(values)}`,
                method: "get",
            }),
        }),
        updateCompanyBatch: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.company.batch}`,
                method: "put",
                body: values.body
            })
        }),
        //Rate
        getRateList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.rate.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        createRate: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.rate.create,
                method: "post",
                body: values
            })
        }),
        updateRate: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.rate.update}/${values.id}`,
                method: "put",
                body: values.body
            })
        }),
        deleteRate: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.rate.delete,
                method: "delete",
                body: values
            })
        }),
        //Accounts
        createAccounts: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.accounts.create,
                method: "post",
                body: values
            })
        }),
        deleteAccounts: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.accounts.delete,
                method: "delete",
                body: values
            })
        }),
        updateAccounts: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.accounts.update}/${values.id}`,
                method: "put",
                body: values.body
            })
        }),
        getAccountsList: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.accounts.list}?${stringify(values)}`,
                method: "get",
            })
        }),
        getAccountAll: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.accounts.all}?${stringify(values)}`,
                method: "get",
            }),
        }),
        updateAccountBatch: builders.mutation({
            query: (values) => ({
                url: `${ConfigApp.api.accounts.batch}`,
                method: "put",
                body: values.body
            })
        }),

        // auth
        updateAuth: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.auth.update,
                method: "post",
                body: values
            })
        }),
        getDetailAuth: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.auth.detail,
                method: "get",
            })
        }),
        postChangePassword: builders.mutation({
            query: (values) => ({
                url: ConfigApp.api.auth.change_pw,
                method: "post",
                body: values
            })
        })
    })
})