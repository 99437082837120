import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'Reducers/hook'

const AppSidebar: React.FC = (props) => {

    // lấy dữ liệu
    const role_menu = useAppSelector((state) => state.access.UserLogin?.company?.role_menus)

    return (
        <Fragment>
            <div id="sidebar">
                <ul className="side-nav accordion_mnu collapsible">
                    <li>
                        {role_menu?.map(role => (
                            <>
                                {
                                    (role === 5 || role === 0) && (
                                        <NavLink to="company">
                                            <span className="white-icons home"></span> Công ty
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 1 || role === 0) && (
                                        <NavLink to="">
                                            <span className="white-icons computer_imac"></span> Màn hình
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 2 || role === 0) && (
                                        <NavLink to="player">
                                            <span className="white-icons create_write"></span> Thiếp lập màn hình
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 3 || role === 0) && (
                                        <NavLink to="resource">
                                            <span className="white-icons folder"></span> Tài nguyên
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 8 || role === 0) && (
                                        <NavLink to="resource-relationship">
                                            <span className="white-icons create_write"></span> Thiết lập tài nguyên
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 4 || role === 0) && (
                                        <NavLink to="composer">
                                            <span className="white-icons record"></span> Chiến dịch
                                        </NavLink>
                                    )
                                }
                                {
                                    (role === 6 || role === 0) && (
                                        <NavLink to="account">
                                            <span className="white-icons admin_user"></span> Tài khoản
                                        </NavLink>
                                    )
                                }
                                {/* {
                                    (role === 7 || role === 0) && (
                                        <NavLink to="rate">
                                            <span className="white-icons speech_bubbles"></span> Đánh giá
                                        </NavLink>
                                    )
                                } */}
                            </>
                        ))}
                        <NavLink to="auth">
                            <span className="white-icons cog_3"></span> Cài đặt
                        </NavLink>
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}

export default AppSidebar;